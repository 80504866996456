import axios from "axios";
import store from "@/vuex/store";
import {
  Toast,
  Indicator
} from "mint-ui";

axios.defaults.baseURL = "/api";

//发送请求之前
axios.interceptors.request.use((config) => {
  Indicator.open("加载中...");
  store.commit("setAjaxCount", store.getters.getAjaxCount + 1);
  sessionStorage.getItem("token") && (config.headers["token"] = JSON.parse(sessionStorage.getItem("token")).token);
  return config;
}, (error) => {
  return Promise.reject(error);
});

//响应数据
axios.interceptors.response.use((response) => {
  const urlList = [
    "/common/tianyancha-search"
  ];
  store.getters.getAjaxCount > 0 && store.commit("setAjaxCount", store.getters.getAjaxCount - 1);
  store.getters.getAjaxCount <= 0 && Indicator.close();
  response.data.code != 200 && !urlList.includes(response.config.url) && Toast(response.data.msg);
  return response.data;
}, (error) => {
  Toast("网络开小差......");
  return Promise.reject(error);
});

export default axios;
