const reportCenter = [{
  "path": "/reportCenter",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/reportCenter/index"], resolve)
}, {
  "path": "/reportCenter/apparatus",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatus/index"], resolve)
}, {
  "path": "/reportCenter/apparatus/sale/twoLevel/:item/:typeValue/:year/:month",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatus/sale/twoLevel"], resolve)
}, {
  "path": "/reportCenter/apparatus/sale/threeLevels/:item/:towItem/:typeValue/:year/:month",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatus/sale/threeLevels"], resolve)
}, {
  "path": "/reportCenter/apparatus/goods/twoLevel/:item/:year/:month",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatus/goods/twoLevel"], resolve)
}, {
  "path": "/reportCenter/apparatus/goods/threeLevels/:item/:towItem/:typeValue/:year/:month",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatus/goods/threeLevels"], resolve)
}, {
  "path": "/reportCenter/drugs",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/reportCenter/drugs/index"], resolve)
}, {
  "path": "/reportCenter/drugs/twoLevel/:medicine/:medicineValue/:year/:month",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/reportCenter/drugs/twoLevel"], resolve)
}, {
  "path": "/reportCenter/drugs/threeLevels/:medicine/:medicineValue/:towItem/:typeValue/:year/:month",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/reportCenter/drugs/threeLevels"], resolve)
}, {
  "path": "/reportCenter/apparatusFlow",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatusFlow/index"], resolve)
}, {
  "path": "/reportCenter/apparatusFlow/twoLevel/:year/:month/:goodsAbbreviatedName",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatusFlow/twoLevel"], resolve)
}, {
  "path": "/reportCenter/apparatusFlow/threeLevels/:year/:month/:goodsAbbreviatedName/:queryTypeValue/:level2Item",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/reportCenter/apparatusFlow/threeLevels"], resolve)
}, {
  "path": "/reportCenter/achievements",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/reportCenter/achievements/index"], resolve)
}, {
  "path": "/reportCenter/visit",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/reportCenter/visit/index"], resolve)
}, {
  "path": "/reportCenter/visit/twoLevel/:year/:month/:staffId",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/reportCenter/visit/twoLevel"], resolve)
}, {
  "path": "/reportCenter/active",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/reportCenter/active/index"], resolve)
}, {
  "path": "/reportCenter/active/twoLevel/:year/:month/:staffId",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/reportCenter/active/twoLevel"], resolve)
}];

export default reportCenter;
