import axios from "@/axios/axiosConfig";

export const allApi = (ajaxUrlArr, callBack) => {
  return axios.all(ajaxUrlArr).then(axios.spread((...args) => {
    callBack(args);
  }));
};

export const getConfigJson = () => {
  return axios({
    url: "/common/getConfigJson",
    method: "get"
  });
};

export const qqMapGeocoder = (params) => {
  return axios({
    url: "/common/qq-map-geocoder",
    method: "post",
    data: params
  });
};

export const qqMmapSuggestion = (params) => {
  return axios({
    url: "/common/qq-map-suggestion",
    method: "post",
    data: params
  });
};

export const queryUserInfo = (params) => {
  return axios({
    url: "/user/queryUserInfo",
    method: "post",
    data: params
  });
};

export const getJsSdkSignature = (params) => {
  return axios({
    url: "/user/getJsSdkSignature",
    method: "post",
    data: params
  });
};

export const uploadFileApi = (params) => {
  return axios({
    url: "/file/upload-file",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    method: "post",
    data: params
  });
};

export const deleteFile = (params) => {
  return axios({
    url: `/file/delete-file/${params.id}`,
    method: "get"
  });
};
