const productInquiry = [{
  "path": "/productInquiry",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/productInquiry/index"], resolve)
},{
  "path": "/productInquiry/productInfo",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/productInquiry/productInfo/index"], resolve)
},{
  "path": "/productInquiry/specialPrice",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/productInquiry/specialPrice/index"], resolve)
}];

export default productInquiry;
