import Vue from "vue";
import VueRouter from "vue-router";
import wx from "weixin-js-sdk";
import {
  allApi,
  getConfigJson,
  queryUserInfo,
  getJsSdkSignature
} from "@/api/base";

const routeList = [],
  files = require.context("@/router", true, /\.js/);

files.keys().forEach(key => {
  if (key.indexOf("index") < 0) {
    files(key).default.map(route => {
      routeList.push(route);
    });
  }
});

const router = new VueRouter({
  mode: "history",
  routes: routeList
});

router.beforeEach((to, from, next) => {
  // document.title = "";
  if (!sessionStorage.getItem("token") || !sessionStorage.getItem("configJson")) {
    allApi(
      [
        queryUserInfo({
          code: to.query.code,
          state: to.query.state
        }),

        getConfigJson()
      ],
      args => {
        if (args[0] && args[0].data) {
          sessionStorage.setItem("token", JSON.stringify(args[0].data));
        }
        if (args[1] && args[1].data) {
          console.log("configJson:" + JSON.stringify(args[1].data));
          sessionStorage.setItem("configJson", JSON.stringify(args[1].data));
        }
        if (args[2] && args[2].data) {
          console.log("configJson:" + JSON.stringify(args[2].data));
          sessionStorage.setItem("configJson", JSON.stringify(args[2].data));
        }
        getJsSdkSignature({
          url: window.location.href
        }).then(xhr => {
          wx.config({
            beta: true,
            debug: false,
            appId: xhr.data.appId,
            timestamp: xhr.data.timestamp,
            nonceStr: xhr.data.nonceStr,
            signature: xhr.data.signature,
            jsApiList: ["showOptionMenu", "hideOptionMenu"]
          });
          wx.ready(() => {
            wx.hideOptionMenu();
          });
        });
        next();
      }
    );
  } else {
    if (to.name == "universityDetail") {
      wx.ready(() => {
        wx.showOptionMenu();
      });
    } else {
      wx.ready(() => {
        wx.hideOptionMenu();
      });
    }
    next();
  }
});

router.afterEach(() => {});

Vue.use(VueRouter);

export default router;
