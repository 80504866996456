<template>
  <div id="app" class="app-box">
    <transition :name="transitionName">
      <keep-alive :include="cachePage">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  data () {
    return {
      transitionName: ""
    };
  },
  created () { },
  methods: {},
  computed: {
    cachePage () {
      return this.$store.getters.getCachePage;
    }
  },
  watch: {
    $route (to, from) {
      if (!from.meta.index) {
        this.transitionName = "slide-left";
      } else {
        if (to.meta.index > from.meta.index) {
          this.transitionName = "slide-left";
        } else {
          this.transitionName = "slide-right";
        }
      }
    }
  }
};
</script>
