import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  refresh: null,
  ajaxCount: 0,
  cachePage: [],
  searchValue: null,
  searchDrugsValue: null,
  searchDeviceValue: null
};

const getters = {
  getRefresh: () => state.refresh,
  getAjaxCount: () => state.ajaxCount,
  getCachePage: () => state.cachePage,
  getSearchValue: () => state.searchValue,
  getSearchDrugsValue: () => state.searchDrugsValue,
  getSearchDeviceValue: () => state.searchDeviceValue
};

const mutations = {
  setRefresh: (state, data) => state.refresh = data,
  setAjaxCount: (state, data) => state.ajaxCount = data,
  setCachePage: (state, data) => state.cachePage = data,
  setSearchValue: (state, data) => state.searchValue = data,
  setSearchDrugsValue: (state, data) => state.searchDrugsValue = data,
  setSearchDeviceValue: (state, data) => state.searchDeviceValue = data
};

const actions = {};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions
});
