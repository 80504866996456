const customerCenter = [{
  "path": "/customerCenter",
  "name": "customerCenterIndex",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/customerCenter/index"], resolve)
}, {
  "path": "/customerCenter/detail/:customerId/:customerType",
  "name": "customerCenterDetailIndex",
  "meta": {
    "index": 2
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/index"], resolve)
}, {
  "path": "/customerCenter/baseInfo/edit/:type/:customerType/:id?",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/baseInfo/edit"], resolve)
}, {
  "path": "/customerCenter/baseInfo/addressMap/:keyWord?",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/baseInfo/addressMap"], resolve)
}, {
  "path": "/customerCenter/staffInfo/read/:customerType/:staffId",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/staffInfo/read"], resolve)
}, {
  "path": "/customerCenter/staffInfo/edit/:type/:customerType/:orgId?",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/staffInfo/edit"], resolve)
}, {
  "path": "/customerCenter/hardwareInfo/read/:hardwareId/:hardwareType/:customerType",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/hardwareInfo/read"], resolve)
}, {
  "path": "/customerCenter/hardwareInfo/edit/:type/:hardwareId/:hardwareType/:customerType",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/hardwareInfo/edit"], resolve)
}, {
  "path": "/customerCenter/drugsInfo/read/:customerType/:id",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/drugsInfo/read"], resolve)
}, {
  "path": "/customerCenter/drugsInfo/edit/:type/:customerType/:id",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/customerCenter/detail/drugsInfo/edit"], resolve)
}];

export default customerCenter;
