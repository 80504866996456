const visitRecord = [{
  "path": "/visitRecord",
  "name": "visitRecordIndex",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/visitRecord/index"], resolve)
}, {
  "path": "/visitRecord/detail/:id/:isEdit?",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/visitRecord/detail"], resolve)
}, {
  "path": "/visitRecord/edit/:type/:id",
  "meta": {
    "index": 6
  },
  "component": (resolve) => require(["@/page/visitRecord/edit"], resolve)
}, {
  "path": "/visitRecord/pick/:customerId",
  "meta": {
    "index": 7
  },
  "component": (resolve) => require(["@/page/visitRecord/pick"], resolve)
}, {
  "path": "/visitRecord/device",
  "meta": {
    "index": 7
  },
  "component": (resolve) => require(["@/page/visitRecord/device"], resolve)
}];

export default visitRecord;
