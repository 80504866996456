(() => {
  const onFooEndFunc = (fn) => {
    let executionTimer = 0;
    const delay = 400;
    clearTimeout(executionTimer);
    executionTimer = setTimeout(() => {
      fn();
    }, delay);
  };
  (function GetSize() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || window.screen.width,
      height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || window.screen.height;
    let minNumber = Math.min.apply(null, [width, height]);
    minNumber = minNumber < 320 ? 320 : minNumber;
    document.documentElement.style.fontSize = minNumber * (100 / 375) + "px";
    if (!window.onresize) {
      window.onresize = () => {
        onFooEndFunc(GetSize);
      };
    }
  })();
})();
