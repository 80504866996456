const searchPage = [{
  "path": "/searchPage/:type/:customerType?",
  "meta": {
    "index": 5
  },
  "component": (resolve) => require(["@/page/searchPage/index"], resolve)
}, {
  "path": "/searchCustomerAssignment/:type",
  "meta": {
    "index": 9
  },
  "component": (resolve) => require(["@/page/searchPage/index"], resolve)
}, {
  "path": "/searchDrugs/:type/:vuexKey?",
  "meta": {
    "index": 8
  },
  "component": (resolve) => require(["@/page/searchPage/index"], resolve)
}, {
  "path": "/searchPick/:type/:customerId",
  "meta": {
    "index": 8
  },
  "component": (resolve) => require(["@/page/searchPage/index"], resolve)
}, {
  "path": "/searchCustomerCenter/:type/:customerId/:customerType/:hardwareType?",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/searchPage/index"], resolve)
}, {
  "path": "/searchVisit/:type",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/searchPage/index"], resolve)
}, {
  "path": "/leaderVisitSearch",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/searchPage/leaderVisitSearch"], resolve)
},{
  "path": "/leaderEntertainListSearch",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/searchPage/leaderEntertainListSearch"], resolve)
}, {
  "path": "/searchEntertainList/:type",
  "meta": {
    "index": 3
  },
  "component": (resolve) => require(["@/page/searchPage/entertainListIndex"], resolve)
}];

export default searchPage;
