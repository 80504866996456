const entertainList = [{
  "path": "/entertainList",
  "name": "entertailListIndex",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/entertainList/index"], resolve)
}, {
  "path": "/entertainList/detail/:id/:isEdit?",
  "meta": {
    "index": 4
  },
  "component": (resolve) => require(["@/page/entertainList/detail"], resolve)
}, {
  "path": "/entertainList/edit/:id",
  "meta": {
    "index": 6
  },
  "component": (resolve) => require(["@/page/entertainList/edit"], resolve)
}, {
  "path": "/entertainList/pick/:customerId",
  "meta": {
    "index": 7
  },
  "component": (resolve) => require(["@/page/entertainList/pick"], resolve)
}, {
  "path": "/entertainList/device",
  "meta": {
    "index": 7
  },
  "component": (resolve) => require(["@/page/entertainList/device"], resolve)
}, {
  "path": "/entertainList/getPrintData/:id",
  "meta": {
    "index": 8
  },
  "component": (resolve) => require(["@/page/entertainList/detail"], resolve)
}];

export default entertainList;
