const customerAssignment = [{
  "path": "/customerAssignment",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/customerAssignment/index"], resolve)
}, {
  "path": "/customerAssignment/resourceList/:saleId",
  "meta": {
    "index": 6
  },
  "component": (resolve) => require(["@/page/customerAssignment/resourceList"], resolve)
}, {
  "path": "/customerAssignment/detail/:id/:name/:orgName",
  "meta": {
    "index": 7
  },
  "component": (resolve) => require(["@/page/customerAssignment/detail"], resolve)
}, {
  "path": "/customerAssignment/batchMaintain/:idList/:name/:pageBack",
  "meta": {
    "index": 8
  },
  "component": (resolve) => require(["@/page/customerAssignment/batchMaintain"], resolve)
}];

export default customerAssignment;
