const university = [{
  "path": "/university",
  "meta": {
    "index": 1
  },
  "component": (resolve) => require(["@/page/university/list"], resolve)
}, {
  "path": "/university/detail/:id",
  "name":"universityDetail",
  "meta": {
    "index": 6
  },
  "component": (resolve) => require(["@/page/university/detail"], resolve)
}];

export default university;
