import "@/assets/style/base.css";
import "@/assets/style/public.css";
import "@/assets/style/resetMintUI.css";

import "@/script/convertRem";

import Vue from "vue";
import App from "@/App";
import store from "@/vuex/store";
import echarts from "echarts/lib/echarts";

import router from "@/router";

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
